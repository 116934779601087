import store from "@/state/store.js";
import downloadcsv from "../../../helpers/downloadcsv";
export default {
  name: "users",
  computed: {
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    }
  },
  methods: {
    importFiles() {
      let id = "";
      let currentRoute = this.$router.currentRoute.path;
      if (currentRoute == "/admin/users/all") {
        id = "0";
      } else if (currentRoute == "/admin/users/corporate") {
        id = "1";
      } else if (currentRoute == "/admin/users/individual") {
        id = "2";
      } else if (currentRoute == "/admin/users/fraudulent") {
        id = "3";
      } else if (currentRoute == "/admin/users/inactive") {
        id = "4";
      }
      store.dispatch("admin/users/importFiles", id).then(res => {
        downloadcsv(res.data, `Users_${currentRoute.split("/")[3].charAt(0).toUpperCase() + currentRoute.split("/")[3].slice(1)}`);
      });
    }
  },
  mounted() {
    store.dispatch("admin/users/getInactiveUsers");
  }
};